module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nick Torba","short_name":"Nick","start_url":"/","background_color":"#ffffff","theme_color":"#c95900","icon":"static/icon.png","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"84e5c94752e2fa113e37b987f8a31514"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/nicktorba/GitHub/nicktorba.com/src/templates/default.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"ordered":false,"fromHeading":1,"toHeading":6,"className":"table-of-contents"}},"gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-embed-video","options":{"width":"560","related":false,"noIframeBorder":true}}]},
    }]
